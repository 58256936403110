<template>
  <div class="user">
    <!-- 会员管理 -->
    <all-log :dialogLog="dialogLog" />
    <!-- 修改用户 -->

    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <div class="menu-box">
        <h2>会员列表</h2>
        <div class="box-bd">
          <el-row :gutter="20">
            <el-col :span="5">
              <el-input placeholder="按手机号搜索" v-model="getInfo.phone">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="catalogue"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="8">
              <el-button type="warning" @click="dialogOpen">查看日志</el-button>
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <!-- <el-table-column prop="nick_name" label="昵称"> </el-table-column> -->
            <el-table-column prop="openid" label="openid"></el-table-column>

            <el-table-column label="VVIP">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.is_svip === 1" type="danger"
                  >VVIP</el-tag
                >

                <el-tag v-else>非VVIP</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="VIP">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.is_vip === 1" type="warning"
                  >VIP</el-tag
                >
                <el-tag v-else>非VIP</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="手机号"></el-table-column>
            <el-table-column width="300" label="操作">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.is_vip !== 1"
                  @click="setVIP(scope.row, 0)"
                  icon="el-icon-setting"
                  size="mini"
                  type="warning"
                  >设置VIP</el-button
                >
                <el-button
                  v-else
                  size="mini"
                  icon="el-icon-edit"
                  @click="setVIP(scope.row, 1)"
                  >取消VIP</el-button
                >
                <el-button
                  v-if="scope.row.is_svip !== 1"
                  size="mini"
                  type="warning"
                  icon="el-icon-setting"
                  @click="setVVIP(scope.row, 0)"
                  >设置VVIP</el-button
                >
                <el-button
                  v-else
                  size="mini"
                  icon="el-icon-edit"
                  @click="setVVIP(scope.row, 1)"
                  >取消VVIP</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.getInfo.limit"
            background
            :pager-count="15"
            :total="exp.num"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog title="设置VIP" :visible.sync="setDiaVisible" width="80%">
      <el-form :model="setForm">
        <el-form-item
          prop="is_svip"
          label="会员类型"
          :label-width="formLabelWidth"
        >
          <el-select
            style="width: 100%"
            v-model="setForm.is_svip"
            placeholder="选择会员类型"
          >
            <el-option label="普通会员" :value="0" />

            <el-option label="vvip" :value="1" />
            <el-option label="vip" :value="2" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setDiaVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmSet()">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getAdd,
  getState,
  sendCode,
  setSvip,
  setVip,
} from '@/api/member/listMember.js'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      setDiaVisible: false,
      dialogLog: {
        state: false,
      },
      timer: '',
      show: true,
      count: 0,
      openState: true,
      //配置
      optionProps: {
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'son',
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        limit: 15,
        page_code: '',
        page: 1,
        phone: '',
      },
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: '一级',
        },
        {
          id: 2,
          name: '二级',
        },
      ],
      //上级列表
      options: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        name: '',
        id_nummber: '',
        password: '',
        affirm: '',
        phone: '',
        verify: '',
      },
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
      setForm: {
        userid: '',
        is_svip: '',
      },
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async confirmSet() {
      const { data: res } = await setSvip(this.setForm)

      if (res.code === 1) {
        this.$message.success('修改成功')
      } else {
        this.$message.error(res.message)
      }
      this.catalogue()
    },
    openset(row) {
      this.setDiaVisible = true
      this.setForm.userid = row.id
      this.setForm.is_svip = row.is_svip
    },
    setVIP(row, flag) {
      let text = '确认将该用户设置为VIP？'

      if (flag === 1) {
        text = '确认取消该用户的VIP权限？'
      }
      this.$confirm(text)
        .then(async (_) => {
          const { data: res } = await setVip({ userid: row.id })

          if (res.code === 1) {
            this.$message.success('修改成功')
          } else {
            this.$message.error(res.message)
          }
          this.catalogue()

          done()
        })
        .catch((_) => {})
    },
    setVVIP(row, flag) {
      let text = '确认将该用户设置为VVIP？'

      if (flag === 1) {
        text = '确认取消该用户的VVIP权限？'
      }
      this.$confirm(text)
        .then(async (_) => {
          const { data: res } = await setSvip({ userid: row.id })

          if (res.code === 1) {
            this.$message.success('修改成功')
          } else {
            this.$message.error(res.message)
          }
          this.catalogue()

          done()
        })
        .catch((_) => {})
    },
    dialogOpen() {
      this.dialogLog.state = true
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.limit = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page = value
      this.catalogue()
    },
    //打开添加
    addOpen() {
      this.openState = true
      this.dialogFormVisible = true
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    //修改状态
    async changeState(data) {
      let info = await getState({ id: data.id })
      this.judge(info)
    },
    //添加form
    async setAdd(e) {
      let info = await getAdd(this.form)
      this.judge(info)
    },
    async setChange(data) {
      let info = await getState({ id: data })
      this.judge(info)
    },
    judge(info, type) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        if (!type) {
          this.dialogFormVisible = false
        }
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        name: '',
        id_nummber: '',
        password: '',
        affirm: '',
        phone: '',
        verify: '',
      }
      console.log('close')
    },
    async getCode() {
      let info = await sendCode({
        phone: this.form.phone,
        type: 2,
      })
      this.judge(info, true)
    },
    async etCode() {
      // 验证码倒计时
      this.getCode()
      if (!this.timer) {
        this.count = 30
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 30) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    //目录接口
    async catalogue() {
      let formdata = new FormData()
      for (let key in this.getInfo) {
        formdata.append(key, this.getInfo[key])
      }
      let info = await getList(formdata)
      console.log(info)
      this.tableData = info.data.data.data
      //   this.exp.count = info.data.total
      //console.log(info.data.data)
      this.exp.num = info.data.data.total
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.tab {
  width: 100%;
}
.tab .tab-tit {
  width: 100px;
  font-weight: bold;
}
.tab td {
  padding: 5px;
  vertical-align: baseline;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand >>> .el-form-item__label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand >>> .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
